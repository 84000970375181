import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { ActionSelectorComponent } from './action-selector/action-selector.component';
import { ConditionSelectorComponent } from './condition-selector/condition-selector.component';
import { CycleTeamMembersComponent } from './cycle-team-members/cycle-team-members.component';
import { SupportStepsComponent } from './support-steps.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, NgxTippyModule],
  declarations: [SupportStepsComponent, CycleTeamMembersComponent, ActionSelectorComponent, ConditionSelectorComponent],
  exports: [SupportStepsComponent],
})
export class SupportStepsModule {}
