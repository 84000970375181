import { Component, Input } from '@angular/core';

import { SUPPORT_STEP_ASSIGNEE_TYPES } from 'src/app/constants';
import { SearchablePopoverService } from 'src/services/searchable-popover.service';

@Component({
  selector: 'app-action-selector',
  templateUrl: './action-selector.component.html',
  styleUrls: ['./action-selector.component.scss'],
})
export class ActionSelectorComponent {
  @Input() type: string;
  @Input() action: any;
  @Input() disabled: boolean;
  @Input() assigneeLookups: any[];
  @Input() collaboratorTypes: any[];
  @Input() channels: any[];

  constructor(private searchablePopoverService: SearchablePopoverService) {}

  showAssigneeOptions(supportStep: any) {
    this.searchablePopoverService.assignees({
      event,
      selectedId: supportStep.assigneeId,
      showCreatedByUserOption: true,
      showPrimaryRepOption: true,
      showNoneOption: true,
      callback: async (id: string, _name: string, object: any) => {
        supportStep.assigneeId = id;
        supportStep.assigneeType = object?.type ?? SUPPORT_STEP_ASSIGNEE_TYPES.USER;
      },
    });
  }

  channelCompareFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === null);
  }
}
