<div *ngIf="supportsConditionalRules()">
  <ion-list *ngFor="let branch of supportStep.branches; let j = index">
    <div class="hr-container">
      <hr />
      <ion-label class="hr-text"
        >Condition {{ j + 1 }} <a *ngIf="!disabled" (click)="removeConditionalRule(j)">(remove)</a></ion-label
      >
      <hr />
    </div>

    <div class="inner-padding">
      <div>{{ j === 0 ? 'If' : 'Else if' }}</div>
      <div style="display: flex; align-items: center; justify-content: space-between">
        <ion-select
          interface="popover"
          style="width: 38%"
          class="select-outline"
          [(ngModel)]="branch.conditions?.[0].field"
          [disabled]="disabled"
          (ionChange)="onConditionChange(branch.conditions?.[0], j)"
        >
          <ion-select-option *ngFor="let condition of conditionValues()" [value]="condition[0]">{{
            condition[1].label
          }}</ion-select-option>
        </ion-select>
        <ion-select
          [disabled]="CONDITIONS[branch.conditions?.[0].field]?.types?.length === 1"
          interface="popover"
          style="width: 20%"
          class="select-outline"
          [(ngModel)]="branch.conditions?.[0].operator"
          [disabled]="disabled"
        >
          <ion-select-option
            *ngFor="let conditionType of CONDITIONS[branch.conditions?.[0].field]?.types"
            [value]="conditionType"
            >{{ CONDITION_TYPES[conditionType] }}</ion-select-option
          >
        </ion-select>
        <ion-chip
          mode="ios"
          class="select-chip select-outline"
          [outline]="true"
          (click)="showSearchOptions(branch.conditions?.[0])"
          [disabled]="isLoading || disabled"
          style="width: 38%"
        >
          <ion-label>
            <!-- Remove this when we support multiple types of condition values -->
            <ion-icon name="pricetag"></ion-icon>
            {{ lookups[branch.conditions?.[0].field]?.[branch.conditions?.[0].value] ?? 'Select an option...' }}
          </ion-label>
        </ion-chip>
      </div>
    </div>

    <div class="inner-padding">
      <div>Then</div>
      <ion-card class="inner-card">
        <app-action-selector
          [disabled]="disabled"
          [action]="branch"
          [type]="supportStep.type"
          [assigneeLookups]="assigneeLookups"
          [collaboratorTypes]="collaboratorTypes"
          [channels]="channels"
        ></app-action-selector>
      </ion-card>
    </div>
  </ion-list>

  <div *ngIf="supportStep.branches?.length">
    <div style="display: flex; align-items: center; justify-content: center; margin: 2px 0 12px 0">
      <ion-button fill="clear" (click)="addConditionalRule()" class="btn-small" [disabled]="disabled">
        <ion-icon name="add-outline" class="padded-icon"></ion-icon>
        Add condition
      </ion-button>
    </div>

    <div class="hr-container">
      <hr />
      <ion-label class="hr-text">Default</ion-label>
      <hr />
    </div>
  </div>
</div>
