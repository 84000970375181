<ng-container *ngIf="type === 'assignment'">
  <ion-item [disabled]="disabled">
    <ion-label> Assign conversation to </ion-label>
    <ion-chip mode="ios" class="select-chip select-outline" [outline]="true" (click)="showAssigneeOptions(action)">
      <ion-label>
        {{ assigneeLookups[action.assigneeId]?.displayName ?? 'No one' }}
        <ion-icon name="caret-down-outline" class="icon-padded-left"></ion-icon>
      </ion-label>
    </ion-chip>
  </ion-item>
  <app-cycle-team-members [disabled]="disabled" [supportStep]="action"></app-cycle-team-members>
</ng-container>

<ng-container *ngIf="type === 'escalation'">
  <ion-item [disabled]="disabled">
    <ion-label> Escalate conversation to </ion-label>
    <ion-chip mode="ios" class="select-chip select-outline" [outline]="true" (click)="showAssigneeOptions(action)">
      <ion-label>
        {{ assigneeLookups[action.assigneeId]?.displayName ?? 'No one' }}
        <ion-icon name="caret-down-outline" class="icon-padded-left"></ion-icon>
      </ion-label>
    </ion-chip>
  </ion-item>
  <app-cycle-team-members [disabled]="disabled" [supportStep]="action"></app-cycle-team-members>
</ng-container>

<ng-container *ngIf="type === 'reminder'"> </ng-container>

<ng-container *ngIf="type === 'triage'">
  <ion-item [disabled]="disabled">
    <ion-label> Channel </ion-label>
    <ion-select slot="end" interface="popover" [(ngModel)]="action.channelId" [compareWith]="channelCompareFn">
      <ion-select-option value=""> Default </ion-select-option>
      <ion-select-option *ngFor="let opt of channels" [value]="opt.id">
        {{ opt.displayName }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item [disabled]="disabled">
    <ion-label> Tag user or team </ion-label>
    <ion-chip mode="ios" class="select-chip select-outline" [outline]="true" (click)="showAssigneeOptions(action)">
      <ion-label>
        {{ assigneeLookups[action.assigneeId]?.displayName ?? 'No one' }}
        <ion-icon name="caret-down-outline" class="icon-padded-left"></ion-icon>
      </ion-label>
    </ion-chip>
  </ion-item>
</ng-container>

<ng-container *ngIf="type === 'addFollower'">
  <ion-item [disabled]="disabled">
    <ion-label> Add and notify user or team </ion-label>
    <ion-chip mode="ios" class="select-chip select-outline" [outline]="true" (click)="showAssigneeOptions(action)">
      <ion-label>
        {{ assigneeLookups[action.assigneeId]?.displayName ?? 'No one' }}
        <ion-icon name="caret-down-outline" class="icon-padded-left"></ion-icon>
      </ion-label>
    </ion-chip>
  </ion-item>
</ng-container>

<ng-container *ngIf="type === 'addCollaborator'">
  <ion-item [disabled]="disabled">
    <ion-label> Collaborator type </ion-label>
    <ion-select slot="end" interface="popover" [(ngModel)]="action.collaboratorTypeId">
      <ion-select-option *ngFor="let collaboratorType of collaboratorTypes" [value]="collaboratorType.id">
        {{ collaboratorType.name }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item [disabled]="disabled">
    <ion-label> Add and notify user or team </ion-label>
    <ion-chip mode="ios" class="select-chip select-outline" [outline]="true" (click)="showAssigneeOptions(action)">
      <ion-label>
        {{ assigneeLookups[action.assigneeId]?.displayName ?? 'No one' }}
        <ion-icon name="caret-down-outline" class="icon-padded-left"></ion-icon>
      </ion-label>
    </ion-chip>
  </ion-item>
</ng-container>

<ng-container *ngIf="type === 'createApprovalRequest'">
  <ion-item [disabled]="disabled">
    <ion-label> Request an approval from user or team </ion-label>
    <ion-chip mode="ios" class="select-chip select-outline" [outline]="true" (click)="showAssigneeOptions(action)">
      <ion-label>
        {{ assigneeLookups[action.assigneeId]?.displayName ?? 'No one' }}
        <ion-icon name="caret-down-outline" class="icon-padded-left"></ion-icon>
      </ion-label>
    </ion-chip>
  </ion-item>
</ng-container>

<ng-container *ngIf="type === 'restart'"> </ng-container>
