import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cycle-team-members',
  templateUrl: './cycle-team-members.component.html',
  styleUrls: ['./cycle-team-members.component.scss'],
})
export class CycleTeamMembersComponent {
  @Input() supportStep: any;
  @Input() disabled: boolean;

  maxCyclesCompareFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === 0);
  }

  shouldCycleThroughTeamMembersCompareFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === false);
  }

  cycleMinutesCompareFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === 60);
  }
}
