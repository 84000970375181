<div style="margin: 20px 0 10px 0" *ngIf="!isLoading">
  <ion-card class="support-step-card warning-feature-banner" *ngIf="isSlackDisconnected">
    <ion-icon name="warning" class="padded-icon"></ion-icon>
    The Unthread Slack app appears to be disconnected
    <ion-button color="dark" class="btn-small" routerLink="/dashboard/settings/slack" routerDirection="root">
      Check Slack settings
    </ion-button>
  </ion-card>
  <ion-card class="support-step-card" *ngIf="supportSteps?.length">
    <ion-list lines="none">
      <ion-item [disabled]="disabled">
        <ion-label><strong>Step 1: Initial Assignment</strong></ion-label>

        <ion-button
          color="dark"
          [fill]="supportSteps[0].remainOnCurrentSteptIfNoAssigneeFound ? 'clear' : 'solid'"
          slot="end"
          class="item-accessory-button button-dark-border"
          ngxTippy
          (click)="
            supportSteps[0].remainOnCurrentSteptIfNoAssigneeFound =
              !supportSteps[0].remainOnCurrentSteptIfNoAssigneeFound
          "
          [disabled]="disabled"
          data-tippy-content="Skip to next step if no assignee available"
        >
          <ion-icon
            [name]="
              supportSteps[0].remainOnCurrentSteptIfNoAssigneeFound
                ? 'play-skip-forward-circle-outline'
                : 'play-skip-forward-circle'
            "
          ></ion-icon>
        </ion-button>

        <ion-button
          color="dark"
          [fill]="supportSteps[0].branches?.length ? 'solid' : 'clear'"
          slot="end"
          class="item-accessory-button button-dark-border"
          ngxTippy
          (click)="supportSteps[0].branches?.length ? (supportSteps[0].branches = []) : addConditionalRule(0)"
          [disabled]="disabled"
          [hidden]="!supportsConditionalRules(0)"
          data-tippy-content="Add conditions ('if' statements)"
        >
          <ion-icon name="git-branch"> </ion-icon>
        </ion-button>
      </ion-item>

      <app-condition-selector
        [supportStep]="supportSteps[0]"
        [disabled]="disabled"
        [isLoading]="isLoading"
        [lookups]="lookups"
        [channels]="channels"
        [assigneeLookups]="assigneeLookups"
        [collaboratorTypes]="collaboratorTypes"
      ></app-condition-selector>

      <ion-item [disabled]="disabled">
        <ion-label> Assign conversation to </ion-label>
        <ion-chip
          mode="ios"
          class="select-chip select-outline"
          [outline]="true"
          (click)="showAssigneeOptions(supportSteps[0])"
        >
          <ion-label>
            {{ assigneeLookups[supportSteps[0].assigneeId]?.displayName ?? 'No one' }}
            <ion-icon name="caret-down-outline" class="icon-padded-left"></ion-icon>
          </ion-label>
        </ion-chip>
      </ion-item>
      <app-cycle-team-members [disabled]="disabled" [supportStep]="supportSteps[0]"></app-cycle-team-members>
    </ion-list>
  </ion-card>

  <ion-reorder-group (ionItemReorder)="onReorder($event)" [disabled]="false" style="width: 100%">
    <ng-container *ngFor="let supportStep of supportSteps; let i = index">
      <div *ngIf="i !== 0">
        <div style="text-align: center">
          <ion-icon name="arrow-down-circle" class="support-step-icon" style="opacity: 0.7"></ion-icon>
        </div>
        <ion-card class="support-step-card">
          <ion-list lines="none">
            <ion-item [disabled]="disabled">
              <ion-reorder *ngIf="i !== 0"></ion-reorder>
              <ion-label
                ><strong>Step {{ i + 1 }}</strong></ion-label
              >

              <ion-button
                color="dark"
                [fill]="supportStep.remainOnCurrentSteptIfNoAssigneeFound ? 'clear' : 'solid'"
                slot="end"
                class="item-accessory-button button-dark-border"
                ngxTippy
                (click)="
                  supportStep.remainOnCurrentSteptIfNoAssigneeFound = !supportStep.remainOnCurrentSteptIfNoAssigneeFound
                "
                *ngIf="supportStep.type === 'assignment' || supportStep.type === 'escalation'"
                [disabled]="disabled"
                data-tippy-content="Skip to next step if no assignee available"
              >
                <ion-icon
                  [name]="
                    supportStep.remainOnCurrentSteptIfNoAssigneeFound
                      ? 'play-skip-forward-circle-outline'
                      : 'play-skip-forward-circle'
                  "
                ></ion-icon>
              </ion-button>

              <ion-button
                color="dark"
                [fill]="supportStep.branches?.length ? 'solid' : 'clear'"
                slot="end"
                class="item-accessory-button button-dark-border"
                ngxTippy
                (click)="supportStep.branches?.length ? (supportStep.branches = []) : addConditionalRule(i)"
                [disabled]="disabled"
                [hidden]="!supportsConditionalRules(i)"
                data-tippy-content="Add conditions ('if' statements)"
              >
                <ion-icon name="git-branch"> </ion-icon>
              </ion-button>

              <ion-button
                fill="clear"
                color="dark"
                slot="end"
                (click)="removeSupportStep(i)"
                *ngIf="i !== 0"
                class="item-accessory-button"
                ngxTippy
                data-tippy-content="Remove step"
              >
                <ion-icon name="trash"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-list>
          <div style="padding: 5px 15px; display: flex; align-items: center">
            <ion-select
              interface="popover"
              [(ngModel)]="supportStep.minutes"
              class="select-outline"
              style="width: 50%"
              [disabled]="disabled"
            >
              <ion-select-option [value]="0" *ngIf="supportStep.type !== 'restart'"> Immediately </ion-select-option>
              <ion-select-option [value]="1"> If no response after 1 minute </ion-select-option>
              <ion-select-option [value]="2"> If no response after 2 minutes </ion-select-option>
              <ion-select-option [value]="3"> If no response after 3 minutes </ion-select-option>
              <ion-select-option [value]="4"> If no response after 4 minutes </ion-select-option>
              <ion-select-option [value]="5"> If no response after 5 minutes </ion-select-option>
              <ion-select-option [value]="10"> If no response after 10 minutes </ion-select-option>
              <ion-select-option [value]="15"> if no response after 15 minutes </ion-select-option>
              <ion-select-option [value]="30"> If no response after 30 minutes </ion-select-option>
              <ion-select-option [value]="45"> If no response after 45 minutes </ion-select-option>
              <ion-select-option [value]="60"> If no response after 1 hour </ion-select-option>
              <ion-select-option [value]="120"> If no response after 2 hours </ion-select-option>
              <ion-select-option [value]="180"> If no response after 3 hours </ion-select-option>
              <ion-select-option [value]="240"> If no response after 4 hours </ion-select-option>
              <ion-select-option [value]="300"> If no response after 5 hours </ion-select-option>
              <ion-select-option [value]="360"> If no response after 6 hours </ion-select-option>
              <ion-select-option [value]="720"> If no response after 12 hours </ion-select-option>
              <ion-select-option [value]="1440"> If no response after 24 hours </ion-select-option>
              <ion-select-option [value]="2880"> If no response after 48 hours </ion-select-option>
            </ion-select>
            <ion-icon name="arrow-forward" style="margin: 5px; opacity: 0.7"></ion-icon>
            <ion-select
              class="select-outline"
              interface="popover"
              [(ngModel)]="supportStep.type"
              style="width: 50%"
              [disabled]="disabled"
            >
              <ion-select-option value="assignment"> Reassign conversation </ion-select-option>
              <ion-select-option value="escalation"> Escalate conversation </ion-select-option>
              <ion-select-option value="reminder"> Send a reminder on Slack to assignee </ion-select-option>
              <ion-select-option value="addFollower"> Add follower to conversation </ion-select-option>
              <ion-select-option value="addCollaborator"> Add collaborator to conversation </ion-select-option>
              <ion-select-option value="triage"> Post conversation to triage channel </ion-select-option>
              <ion-select-option value="restart"> Restart actions from the beginning </ion-select-option>
              <ion-select-option value="createApprovalRequest"> Create approval request </ion-select-option>
            </ion-select>
          </div>

          <app-condition-selector
            [supportStep]="supportSteps[i]"
            [disabled]="disabled"
            [isLoading]="isLoading"
            [lookups]="lookups"
            [channels]="channels"
            [assigneeLookups]="assigneeLookups"
            [collaboratorTypes]="collaboratorTypes"
          ></app-condition-selector>

          <ion-list class="no-padding" style="margin-top: 10px; border-top: 1px solid var(--ion-color-step-50)">
            <ng-container *ngIf="supportStep.type === 'assignment'">
              <ion-item [disabled]="disabled">
                <ion-label> Assign conversation to </ion-label>
                <ion-chip
                  mode="ios"
                  class="select-chip select-outline"
                  [outline]="true"
                  (click)="showAssigneeOptions(supportStep)"
                >
                  <ion-label>
                    {{ assigneeLookups[supportStep.assigneeId]?.displayName ?? 'No one' }}
                    <ion-icon name="caret-down-outline" class="icon-padded-left"></ion-icon>
                  </ion-label>
                </ion-chip>
              </ion-item>
              <app-cycle-team-members [disabled]="disabled" [supportStep]="supportStep"></app-cycle-team-members>
            </ng-container>

            <ng-container *ngIf="supportStep.type === 'escalation'">
              <ion-item [disabled]="disabled">
                <ion-label> Escalate conversation to </ion-label>
                <ion-chip
                  mode="ios"
                  class="select-chip select-outline"
                  [outline]="true"
                  (click)="showAssigneeOptions(supportStep)"
                >
                  <ion-label>
                    {{ assigneeLookups[supportStep.assigneeId]?.displayName ?? 'No one' }}
                    <ion-icon name="caret-down-outline" class="icon-padded-left"></ion-icon>
                  </ion-label>
                </ion-chip>
              </ion-item>
              <app-cycle-team-members [disabled]="disabled" [supportStep]="supportStep"></app-cycle-team-members>
            </ng-container>

            <ng-container *ngIf="supportStep.type === 'addFollower'">
              <ion-item [disabled]="disabled">
                <ion-label> Add and notify user or team </ion-label>
                <ion-chip
                  mode="ios"
                  class="select-chip select-outline"
                  [outline]="true"
                  (click)="showAssigneeOptions(supportStep)"
                >
                  <ion-label>
                    {{ assigneeLookups[supportStep.assigneeId]?.displayName ?? 'No one' }}
                    <ion-icon name="caret-down-outline" class="icon-padded-left"></ion-icon>
                  </ion-label>
                </ion-chip>
              </ion-item>
            </ng-container>

            <ng-container *ngIf="supportStep.type === 'addCollaborator'">
              <ion-item [disabled]="disabled">
                <ion-label> Collaborator type </ion-label>
                <ion-select slot="end" interface="popover" [(ngModel)]="supportStep.collaboratorTypeId">
                  <ion-select-option *ngFor="let collaboratorType of collaboratorTypes" [value]="collaboratorType.id">
                    {{ collaboratorType.name }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item [disabled]="disabled">
                <ion-label> Add and notify user or team </ion-label>
                <ion-chip
                  mode="ios"
                  class="select-chip select-outline"
                  [outline]="true"
                  (click)="showAssigneeOptions(supportStep)"
                >
                  <ion-label>
                    {{ assigneeLookups[supportStep.assigneeId]?.displayName ?? 'No one' }}
                    <ion-icon name="caret-down-outline" class="icon-padded-left"></ion-icon>
                  </ion-label>
                </ion-chip>
              </ion-item>
            </ng-container>

            <ng-container *ngIf="supportStep.type === 'createApprovalRequest'">
              <ion-item [disabled]="disabled">
                <ion-label> Request an approval from user or team </ion-label>
                <ion-chip
                  mode="ios"
                  class="select-chip select-outline"
                  [outline]="true"
                  (click)="showAssigneeOptions(supportStep)"
                >
                  <ion-label>
                    {{ assigneeLookups[supportStep.assigneeId]?.displayName ?? 'No one' }}
                    <ion-icon name="caret-down-outline" class="icon-padded-left"></ion-icon>
                  </ion-label>
                </ion-chip>
              </ion-item>
            </ng-container>

            <ng-container *ngIf="supportStep.type === 'reminder'"> </ng-container>

            <ng-container *ngIf="supportStep.type === 'triage'">
              <ion-item [disabled]="disabled" *ngIf="hasManageChannelsScope">
                <ion-label> Channel type </ion-label>
                <ion-select
                  slot="end"
                  interface="popover"
                  [(ngModel)]="supportStep.createChannel"
                  [compareWith]="createChannelCompareFn"
                >
                  <ion-select-option [value]="false"> Existing channel </ion-select-option>
                  <ion-select-option [value]="true"> Dedicated channel per ticket </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item [disabled]="disabled" *ngIf="supportStep.createChannel">
                <ion-label> Channel visibility </ion-label>
                <ion-select
                  slot="end"
                  interface="popover"
                  [(ngModel)]="supportStep.createChannelIsPrivate"
                  [compareWith]="createChannelIsPrivateCompareFn"
                >
                  <ion-select-option [value]="true"> Private </ion-select-option>
                  <ion-select-option [value]="false"> Public </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item [disabled]="disabled" *ngIf="supportStep.createChannel">
                <ion-label> Channel prefix </ion-label>
                <ion-input
                  slot="end"
                  [(ngModel)]="supportStep.createChannelPrefix"
                  placeholder="unthread"
                  style="text-align: right"
                >
                </ion-input>
              </ion-item>
              <ion-item [disabled]="disabled" *ngIf="!supportStep.createChannel">
                <ion-label> Channel to post </ion-label>
                <ion-select
                  slot="end"
                  interface="popover"
                  [(ngModel)]="supportStep.channelId"
                  (ngModelChange)="saveOnChange()"
                  [compareWith]="channelCompareFn"
                >
                  <ion-select-option value=""> Default </ion-select-option>
                  <ion-select-option *ngFor="let opt of channels" [value]="opt.id">
                    {{ opt.displayName }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item [disabled]="disabled">
                <ion-label> Tag user or team </ion-label>
                <ion-chip
                  mode="ios"
                  class="select-chip select-outline"
                  [outline]="true"
                  (click)="showSlackEntityOptions(supportStep)"
                >
                  <ion-label>
                    {{ assigneeLookups[supportStep.assigneeId]?.displayName ?? 'No one' }}
                    <ion-icon name="caret-down-outline" class="icon-padded-left"></ion-icon>
                  </ion-label>
                </ion-chip>
              </ion-item>
            </ng-container>

            <ng-container *ngIf="supportStep.type === 'restart'"> </ng-container>
          </ion-list>
        </ion-card>
      </div>
    </ng-container>
  </ion-reorder-group>

  <div style="text-align: center" [hidden]="disabled || isMultiStepDisabled">
    <a (click)="addSupportStep()" ngxTippy data-tippy-content="Add a step">
      <ion-icon name="add-circle" class="support-step-icon"> </ion-icon>
    </a>
  </div>

  <div class="upgrade-feature-banner" *ngIf="isMultiStepDisabled">
    <ion-icon name="arrow-up-circle" class="padded-icon"></ion-icon>
    Upgrade to add multi-step SLAs
  </div>
</div>
