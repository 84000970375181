import { SUPPORT_STEP_TYPES } from 'src/app/constants';

export function addConditionalRule(supportStep: any) {
  const branches = supportStep.branches ?? [];

  branches.push({
    conditions: [
      {
        field: 'tags.id',
        operator: 'contains',
        value: '',
      },
    ],
  });

  supportStep.branches = branches;
}

export function supportsConditionalRules(supportStep: any) {
  return [
    SUPPORT_STEP_TYPES.ASSIGNMENT,
    SUPPORT_STEP_TYPES.ESCALATION,
    SUPPORT_STEP_TYPES.TRIAGE,
    SUPPORT_STEP_TYPES.ADD_COLLABORATOR,
    SUPPORT_STEP_TYPES.ADD_FOLLOWER,
    SUPPORT_STEP_TYPES.CREATE_APPROVAL_REQUEST,
  ].includes(supportStep.type);
}
